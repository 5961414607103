html {
  height: 100%;
}
body {
  min-height: 100%;
}

#root {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
}
body {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: rgb(247, 247, 248); /*depthLight3*/
}

header {
  background-color: white;
}

.language-menu-button {
  min-width: 70px;
}
.fi-language-menu-popover {
  min-width: 140px;
}

.app-header-logo {
  width: 60px; 
  height: 60px;
}
.app-header-title {
  margin-left: 10px !important;
}

@media only screen and (max-width: 600px) {
  .app-header-logo {
    width: 40px; 
    height: 40px;
  }

  .app-header-title {
    font-size: 16px !important;
    line-height: 16px !important;
  }
}

section {
  flex-grow: 1;
  border: 1px solid #c8cdd0;
  background: white;
  padding: 10px 30px 30px;
  max-width: 1200px;
  padding: 10px 20px 30px 20px;
  box-sizing: border-box;
  margin: 10px auto;
}

main .fi-heading {
  margin: 20px 0;
}
main .fi-paragraph {
  margin: 10px 0;
}

.longer-input {
  width: 380px !important;
}
/* 
.fi-heading--h1hero.app-header-title {
  font-size: 30px !important;
}
.fi-heading--h1hero.app-header-title svg {
  height: 20px;
  width: 20px;
} */

main {
  flex-grow: 1;
}

article {
  margin: auto;
  max-width: 1200px;
  padding-left: 10px;
  padding-right: 10px;
}

footer {
  background-color: white;
}

footer a {
  font-size: smaller;
  white-space: nowrap;
}


.certificate-table {
  
  width: 100%;
  border: 1px solid black;
}
.certificate-table td {
  border: 1px solid black;
  padding: 5px 10px;
  vertical-align: top;
}

pre {
  white-space:pre-wrap; word-wrap:break-word;
  text-wrap: wrap;
  overflow-x: auto;
}
